import env from '../env'
import { sleep } from './sleep'
import { InjectionService, Keys, ValueForKeys, PromiseForKeys } from '../domain'

const getPrismEntryUrl = () => {
  let url = ''
  if (env.environment === 'local') {
    url = 'http://localhost:3000'
  } else if (env.environment === 'staging') {
    url = 'https://s2.pluralsight.com/prism-staging'
  } else {
    url = 'https://s2.pluralsight.com/prism'
  }
  return `${url}/prism-${env.prismVersion}-${env.gitSha}.js`
}

const defaultRegistry: Record<Keys, string> = {
  prismEntryWindowKey: getPrismEntryUrl(),
  ps_prism_upgrade_button: '',
}

const injectScript = (src: string, id: string): Promise<void> => {
  if (document.getElementById(id)) return Promise.resolve()
  const head = document.getElementsByTagName('head')[0]
  let onError: (e: ErrorEvent) => void

  return new Promise<void>((resolve, reject) => {
    onError = (e: Event) => {
      if (e instanceof ErrorEvent && e.filename.startsWith(src)) {
        reject(e.error)
      }
    }
    const script = document.createElement('script')
    script.id = `script-for-${id}`
    script.type = 'text/javascript'
    script.async = true
    script.onload = () => resolve()
    script.addEventListener('error', () => reject(`${id} failed to load`), true)
    window.addEventListener('error', onError)
    script.src = src

    head.appendChild(script)
  }).finally(() => {
    window.removeEventListener('error', onError)
  })
}

const getFromPartial = <R, K extends keyof R>(
  key: K,
  record: Partial<R>
): R[K] | undefined => {
  return record[key]
}

async function ensurePropOnWindow<Key extends Keys>(
  key: Key
): Promise<ValueForKeys[Key]> {
  const interval = 10
  const timeout = 10000
  const maxChecks = Math.round(timeout / interval)
  const failureError = new Error(
    `Window missing ${key} within timeout (> ${timeout})`
  )

  return new Promise((resolve, reject) => {
    let checks = 0

    const polling = setInterval(function detectKeyOnWindow() {
      const value: ValueForKeys[Key] | undefined = getFromPartial(key, window)
      checks++

      if (value) {
        clearInterval(polling)
        resolve(value)
      } else if (checks > maxChecks) {
        clearInterval(polling)
        reject(failureError)
      }
    }, interval)
  })
}

const makeInjectionService = (): InjectionService => {
  const registry = { ...defaultRegistry }
  const loadMap: Partial<PromiseForKeys> = {}

  const loadChunk = <Key extends Keys>(key: Key): PromiseForKeys[Key] => {
    const loaded = getFromPartial(key, loadMap)
    if (loaded) {
      return loaded
    } else {
      /**
       * There is a problem with type inference where Promise<ValueForKeys[Key]> is not PromiseForKeys[Key].
       * We are confident that these two types are equivalent, so we annotate loaded as the first to make sure it gets to that state and then cast to the second.
       */
      const loaded: Promise<ValueForKeys[Key]> = Promise.race([
        injectScript(registry[key], key),
        sleep(10000).then(() => {
          throw new Error(`Timed out waiting for ${key}`)
        }),
      ]).then(() => ensurePropOnWindow(key))
      loadMap[key] = loaded as PromiseForKeys[Key]
      return loaded as PromiseForKeys[Key]
    }
  }

  return {
    loadChunk,
    remapChunk: (key, url) => {
      if (loadMap[key]) {
        return { success: false, error: 'Chunk remapped after load' }
      } else {
        registry[key] = url
        return { success: true, data: undefined }
      }
    },
    preheat: async () => {
      try {
        await Promise.all(
          (Object.keys(registry) as Keys[])
            .filter((key) => registry[key] !== '')
            .map((key) => loadChunk(key))
        )
      } catch (e) {} //eslint-disable-line
    },
  }
}

export default makeInjectionService
