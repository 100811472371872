import sentryClient from './sentry'
import env from './env'
import { Severity } from '@sentry/browser'

sentryClient.setTag('pluralsight-url', window.location.href)
sentryClient.setTag('prism-version', env.prismVersion)

export const captureException = (error: unknown, fingerprint: string): void => {
  if (env.environment !== 'local') {
    sentryClient.withScope((scope) => {
      const message = error instanceof Error ? error.message : ''
      scope.setFingerprint([fingerprint, message])
      sentryClient.captureException(error, {
        captureContext: (scope) => {
          scope.setContext('Browser Information', {
            userAgent: window.navigator.userAgent,
          })
          return scope
        },
      })
    })
  }
  console.error(error) // eslint-disable-line no-console
}

export const captureInfo = (message: string): void => {
  if (env.environment !== 'local') {
    sentryClient.captureMessage(message, Severity.Info)
  }
  console.info(message) // eslint-disable-line no-console
}

export const captureWarn = (message: string): void => {
  if (env.environment !== 'local') {
    sentryClient.captureMessage(message, Severity.Warning)
  }
  console.warn(message) // eslint-disable-line no-console
}

export const addBreadcrumb = (breadcrumb: Record<string, unknown>): void => {
  if (env.environment !== 'local') {
    sentryClient.addBreadcrumb({ data: breadcrumb })
  } else {
    console.log(breadcrumb)
  }
}

type Transaction = { finish: () => void }

export const startTransaction = (
  name: string,
  description?: string
): Transaction => {
  if (env.environment === 'local') {
    console.log(`Starting transaction ${name}`)
    return {
      finish: () => {
        console.log(`transaction ${name} complete`)
      },
    }
  } else {
    return sentryClient.startTransaction({ name, description })
  }
}

export const withSynchronousTransaction =
  <Args extends unknown[], Return>(
    callback: (..._: Args) => Return,
    name: string
  ): ((..._: Args) => Return) =>
  (...args) => {
    const transaction = startTransaction(name)
    const result = callback(...args)
    transaction.finish()
    return result
  }
