import { Hub, BrowserClient } from '@sentry/browser'
import { Integrations } from '@sentry/tracing'
import env from './env'

const sentryClient = new Hub(
  new BrowserClient({
    dsn: env.sentryDsn,
    maxBreadcrumbs: 15,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: env.environment === 'staging' ? 0.1 : 0.01,
    maxValueLength: 500,
  })
)

export default sentryClient
