import './imported.css'

import { Prism, ConfigInit as PrismConfigInit, Keys } from './domain'
import { captureException } from './logger'
import makeInjectionService from './utils/inject-script'

let isInited = false
const injectionService = makeInjectionService()

let resolveInit: () => void
const initPromise = new Promise<void>((res) => {
  resolveInit = res
})

const Prism: Prism = {
  initPromise() {
    return initPromise
  },
  getConfig() {
    throw new Error('#getConfig available after #init resolves')
  },
  getFeatures() {
    throw new Error('#getFeatures available after #init resolves')
  },
  async getConfigStore() {
    await initPromise
    return window.Prism.getConfigStore()
  },
  async getFeaturesStore() {
    await initPromise
    return window.Prism.getFeaturesStore()
  },

  remapChunk(key: Keys, url: string) {
    return injectionService.remapChunk(key, url)
  },

  async init(configInit: PrismConfigInit) {
    try {
      await injectionService.preheat()
      const prismInit = await injectionService.loadChunk('prismEntryWindowKey')
      await prismInit(configInit, injectionService)
      isInited = true
      resolveInit()
    } catch (e) {
      captureException(e, 'error initializing prism')
    }
  },

  isInited() {
    return isInited
  },

  // eslint-disable-next-line @typescript-eslint/require-await
  update() {
    throw new Error('#update available after #init resolves')
  },

  _updatePayload() {
    throw new Error('#_updatePayload available after #init resolves')
  },
  analytics: {
    track: (_eventName: string, _eventProperties?: Record<string, any>) => {
      throw new Error('#analytics available after #init resolves')
    },
  },
}

window.Prism = Prism
window.PsPrism = Prism
